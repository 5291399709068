import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import router from 'next/router'

import { breakpoint } from '@/theme'
import ROUTES from '@helpers/routes'
import { HighSmiley } from '@microcomponents/shapes/high-smiley'
import { SectionTitle, Text } from '@microcomponents/typography'
import { Button } from '@stories/buttons/button'
import PageContainer from '@stories/microcomponents/page/container'

import { bool, element, InferProps, string } from 'prop-types'

export default function InfoPage({
  title,
  body,
  ctaText,
  textColor,
  buttonVariant,
  image,
  imageSize,
  hideTitle
}: InferProps<typeof InfoPage.propTypes>) {
  const theme = useTheme()

  return (
    <PageContainer
      navbarProps={{
        showBackButton: true,
        hideAddress: true,
        noCheckout: true,
        showSupportButton: true
      }}>
      <Body>
        <Center>
          <ImageContainer imageSize={imageSize}>{image || <HighSmiley />}</ImageContainer>
          <SectionTitle
            color={textColor}
            componentStyle={{
              display: hideTitle ? 'none' : 'block',
              fontFamily: theme.typography.font.npBold,
              marginTop: `${image ? 0 : theme.spacing.medium}px`,
              marginBottom: `${theme.spacing.default}px`
            }}>
            {title}
          </SectionTitle>
          <Text
            color={textColor}
            componentStyle={{ fontFamily: theme.typography.font.sim, marginBottom: `${theme.spacing.large}px` }}>
            {body}
          </Text>
          <Button variant={buttonVariant} onClick={() => router.push(ROUTES.MENU)}>
            {ctaText}
          </Button>
        </Center>
      </Body>
    </PageContainer>
  )
}

InfoPage.propTypes = {
  title: string,
  body: string,
  ctaText: string,
  textColor: string,
  buttonVariant: string,
  image: element,
  imageSize: string,
  hideTitle: bool
}

InfoPage.defaultProps = {
  title: 'Something went wrong...',
  body: 'Either we got too high to create this page, or you got too high and got lost...',
  ctaText: 'Go to Menu',
  textColor: '#000000',
  buttonVariant: 'variant-1',
  hideTitle: false
}

const ImageContainer = styled.div<{ imageSize: string }>`
  width: ${({ imageSize }) => imageSize || '100px'};
  display: flex;
`

const Body = styled.div`
  width: 100vw;
  height: calc(100vh - 64px);
  background-position: top center, bottom center, center;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: ${breakpoint.min.xxl}px, ${breakpoint.min.xxl}px, cover;
  background-image: url(/static/images/bg-decorations-top-min.png), url(/static/images/bg-decorations-bottom-min.png),
    url(/static/images/bg-gradient-full-screen-min.jpg);

  @media (max-width: ${breakpoint.max.lg}px) {
    height: calc(100vh - 56px);
  }

  @media (max-width: ${breakpoint.max.sm}px) {
    height: calc(100vh - 56px);
    background-color: ${({ theme }) => theme.colors.white};
    background-image: url(/static/images/bg-decorations-top-mobile-min.png),
      url(/static/images/bg-decorations-bottom-mobile-min.png), url(/static/images/home-hero-background-mobile-min.jpg);

    background-position: top center, bottom center, center;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: contain, contain, cover;
    padding: 70px 20px;
  }

  @media (max-width: ${breakpoint.max.xs}px) {
    background-position: top center, bottom center, center;
  }

  svg {
    width: 100%;
    height: auto;
  }
`

const Center = styled.div`
  height: 100%;
  width: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: relative;
  flex-direction: column;
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 2rem;

  @media (max-width: ${breakpoint.max.md}px) {
    width: 100%;
  }
`
