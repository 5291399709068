export const HighSmiley = () => (
  <svg fill="none" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m59 30c0-16.016-12.984-29-29-29s-29 12.984-29 29c0 16.017 12.984 29 29 29s29-12.983 29-29z"
      clipRule="evenodd"
      fill="#FFE404"
      fillRule="evenodd"
    />
    <path
      d="m59 30c0-16.016-12.984-29-29-29s-29 12.984-29 29c0 16.017 12.984 29 29 29s29-12.983 29-29z"
      clipRule="evenodd"
      fillRule="evenodd"
      stroke="#000"
      strokeWidth="1.2224"
    />
    <path
      d="m12.919 41.196c2.0744 2.8811 4.8394 5.0313 7.9162 6.3307 1.5084 0.6943 3.0939 1.1613 4.6866 1.462 1.5879 0.3178 3.1966 0.4926 4.8089 0.4804 1.6123 0.0122 3.2198-0.1601 4.8089-0.478 1.5916-0.3007 3.1782-0.7627 4.6854-1.457 3.0719-1.2848 5.8541-3.4729 7.9077-6.2954l0.0195-0.0256 0.0159-0.0257 0.0379-0.0611 0.9743 0.7383-0.0355 0.0587 0.0342-0.0514c-1.9582 3.1269-4.7318 5.7135-7.9504 7.4493-3.2027 1.77-6.8601 2.6758-10.499 2.644-3.6403 0.0294-7.2965-0.8838-10.498-2.6538-3.2088-1.7297-6.0068-4.3517-7.9272-7.4334l1.0146-0.682z"
      clipRule="evenodd"
      fill="#000"
      fillRule="evenodd"
    />
    <path
      d="m49.487 43.02c0.0085-0.7469-0.357-1.4852-1.0317-1.9302-0.0098-0.0086-0.0233-0.0134-0.033-0.0183-0.7298-0.4621-1.6185-0.467-2.3348-0.0819"
      stroke="#000"
      strokeWidth="1.2224"
    />
    <path
      d="m11.156 43.079c-0.0086-0.7384 0.3423-1.4718 1.0085-1.9155 0.0049-0.0049 0.0134-0.0098 0.0183-0.0147 0.7359-0.4804 1.6478-0.489 2.369-0.1002"
      stroke="#000"
      strokeWidth="1.2224"
    />
    <path
      d="m24.884 28.379c0.2983 0.4976 0.4633 1.0501 0.4633 1.6686 0 1.7896-1.4473 3.2369-3.2369 3.2369-1.7883 0-3.2369-1.4473-3.2369-3.2369 0-0.6185 0.1773-1.1821 0.4646-1.6686 0.6295-0.099 1.27-0.165 1.9228-0.1992-0.0893 0.1552-0.1443 0.3325-0.1443 0.5195 0 0.5965 0.4865 1.083 1.0831 1.083 0.5965 0 1.0818-0.4865 1.0818-1.083 0-0.187-0.044-0.3533-0.132-0.5073 0.5855 0.033 1.171 0.088 1.7345 0.187z"
      clipRule="evenodd"
      fill="#000"
      fillRule="evenodd"
    />
    <path
      d="m23.446 27.693c0.1162 0.2029 0.1748 0.4205 0.1748 0.6674 0 0.7836-0.6393 1.4205-1.4228 1.4205-0.7824 0-1.4205-0.6369-1.4205-1.4205 0-0.2469 0.0722-0.4791 0.1883-0.682 0.363-0.0147 0.7395-0.0294 1.1173-0.0294 0.4632 0 0.9131 0.0147 1.3629 0.044z"
      clipRule="evenodd"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      d="m24.884 28.379c-0.5635-0.099-1.149-0.154-1.7346-0.187-0.3422-0.022-0.6845-0.0342-1.039-0.0342-0.286 0-0.5733 0.0122-0.8496 0.022-0.6527 0.0342-1.2932 0.1002-1.9228 0.1992-1.138 0.1773-2.2101 0.4645-3.2039 0.8398-0.3642-1.7896-0.5635-3.7674-0.5635-5.8442 0-7.9321 2.9276-14.362 6.5398-14.362 3.6134 0 6.541 6.4298 6.541 14.362 0 2.0768-0.1992 4.0546-0.5635 5.8442-0.9938-0.3753-2.0658-0.6625-3.2039-0.8398z"
      clipRule="evenodd"
      fill="#FFE404"
      fillRule="evenodd"
    />
    <path
      d="m28.087 29.219c-1.0158 5.0155-3.3139 8.5176-5.9763 8.5176-2.6623 0-4.9604-3.5021-5.9762-8.5176 0.9938-0.3753 2.0658-0.6625 3.2026-0.8398-0.2872 0.4865-0.4633 1.05-0.4633 1.6686 0 1.7895 1.4474 3.2369 3.2369 3.2369 1.7896 0 3.2369-1.4474 3.2369-3.2369 0-0.6186-0.165-1.1711-0.4645-1.6686 1.1381 0.1773 2.2101 0.4645 3.2039 0.8398z"
      clipRule="evenodd"
      fill="#FC8579"
      fillRule="evenodd"
    />
    <path
      d="m16.134 29.219c-0.3642-1.7896-0.5635-3.7674-0.5635-5.8442 0-7.9321 2.9276-14.362 6.5398-14.362s6.5398 6.4298 6.5398 14.362c0 2.0768-0.198 4.0546-0.5635 5.8442-1.0158 5.0155-3.3139 8.5176-5.9763 8.5176s-4.9605-3.5021-5.9763-8.5176z"
      clipRule="evenodd"
      fillRule="evenodd"
      stroke="#000"
      strokeWidth="1.2224"
    />
    <path
      d="m28.087 29.219c-0.9938-0.3752-2.0658-0.6625-3.2039-0.8397-0.5635-0.0991-1.149-0.1541-1.7346-0.1871-0.3422-0.022-0.6845-0.0342-1.0378-0.0342-0.2872 0-0.5745 0.0122-0.8508 0.022-0.6515 0.0342-1.2932 0.1002-1.9228 0.1993-1.1368 0.1772-2.2088 0.4645-3.2026 0.8397"
      stroke="#000"
      strokeWidth="1.2224"
    />
    <path
      d="m19.336 28.368v0.011c-0.2872 0.4865-0.4633 1.05-0.4633 1.6685 0 1.7896 1.4474 3.2357 3.2369 3.2357 1.7884 0 3.2369-1.4461 3.2369-3.2357 0-0.6185-0.1662-1.171-0.4645-1.6685v-0.011"
      stroke="#000"
      strokeWidth="1.2224"
    />
    <path
      d="m41.454 28.379c0.2982 0.4976 0.4632 1.0501 0.4632 1.6686 0 1.7896-1.4473 3.2369-3.2368 3.2369-1.7896 0-3.2369-1.4473-3.2369-3.2369 0-0.6185 0.1772-1.1821 0.4645-1.6686 0.6295-0.099 1.27-0.165 1.9228-0.1992-0.0892 0.1552-0.1443 0.3325-0.1443 0.5195 0 0.5965 0.4853 1.083 1.0831 1.083 0.5965 0 1.0818-0.4865 1.0818-1.083 0-0.187-0.044-0.3533-0.132-0.5073 0.5855 0.033 1.171 0.088 1.7346 0.187z"
      clipRule="evenodd"
      fill="#000"
      fillRule="evenodd"
    />
    <path
      d="m40.017 27.693c0.1161 0.2029 0.1735 0.4205 0.1735 0.6674 0 0.7836-0.6381 1.4205-1.4216 1.4205-0.7823 0-1.4216-0.6369-1.4216-1.4205 0-0.2469 0.0733-0.4791 0.1894-0.682 0.3631-0.0147 0.7396-0.0294 1.1161-0.0294 0.4645 0 0.9143 0.0147 1.3642 0.044z"
      clipRule="evenodd"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      d="m41.454 28.379c-0.5635-0.099-1.149-0.154-1.7346-0.187-0.3422-0.022-0.6845-0.0342-1.039-0.0342-0.2872 0-0.5745 0.0122-0.8495 0.022-0.6528 0.0342-1.2933 0.1002-1.9229 0.1992-1.138 0.1773-2.21 0.4645-3.2039 0.8398-0.3654-1.7896-0.5635-3.7674-0.5635-5.8442 0-7.9321 2.9277-14.362 6.5398-14.362 3.6134 0 6.541 6.4298 6.541 14.362 0 2.0768-0.1992 4.0546-0.5635 5.8442-0.995-0.3753-2.0658-0.6625-3.2039-0.8398z"
      clipRule="evenodd"
      fill="#FFE404"
      fillRule="evenodd"
    />
    <path
      d="m44.657 29.219c-1.017 5.0155-3.3139 8.5176-5.9763 8.5176-2.6623 0-4.9604-3.5021-5.9775-8.5176 0.9951-0.3753 2.0659-0.6625 3.2039-0.8398-0.2872 0.4865-0.4633 1.05-0.4633 1.6686 0 1.7895 1.4461 3.2369 3.2369 3.2369 1.7896 0 3.2369-1.4474 3.2369-3.2369 0-0.6186-0.1662-1.1711-0.4645-1.6686 1.138 0.1773 2.2101 0.4645 3.2039 0.8398z"
      clipRule="evenodd"
      fill="#FC8579"
      fillRule="evenodd"
    />
    <path
      d="m32.704 29.219c-0.3642-1.7896-0.5635-3.7674-0.5635-5.8442 0-7.9321 2.9277-14.362 6.541-14.362 3.6122 0 6.5398 6.4298 6.5398 14.362 0 2.0768-0.1992 4.0546-0.5635 5.8442-1.017 5.0155-3.3151 8.5176-5.9763 8.5176-2.6636 0-4.9604-3.5021-5.9775-8.5176z"
      clipRule="evenodd"
      fillRule="evenodd"
      stroke="#000"
      strokeWidth="1.2224"
    />
    <path
      d="m44.657 29.219c-0.9938-0.3752-2.0659-0.6625-3.2039-0.8397-0.5635-0.0991-1.149-0.1541-1.7346-0.1871-0.3422-0.022-0.6845-0.0342-1.0378-0.0342-0.2885 0-0.5745 0.0122-0.8508 0.022-0.6527 0.0342-1.2933 0.1002-1.9228 0.1993-1.138 0.1772-2.2088 0.4645-3.2039 0.8397"
      stroke="#000"
      strokeWidth="1.2224"
    />
    <path
      d="m35.909 28.368v0.011c-0.2873 0.4865-0.4633 1.05-0.4633 1.6685 0 1.7896 1.4461 3.2357 3.2369 3.2357 1.7884 0 3.2369-1.4461 3.2369-3.2357 0-0.6185-0.1662-1.171-0.4645-1.6685v-0.011"
      stroke="#000"
      strokeWidth="1.2224"
    />
  </svg>
)
