export const FourOhFourSmiley = () => (
  <svg width="178" height="101" viewBox="0 0 178 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M52.9728 58.768H44.6928V70H33.5328V58.768H0.556812V58.624L23.9568 19.168H44.6928V49.552H52.9728V58.768ZM33.5328 49.552V22.696H33.4608L18.1968 49.552H33.5328Z"
      fill="black"
    />
    <path
      d="M176.973 58.768H168.693V70H157.533V58.768H124.557V58.624L147.957 19.168H168.693V49.552H176.973V58.768ZM157.533 49.552V22.696H157.461L142.197 49.552H157.533Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120 44C120 27.9842 107.016 15 91 15C74.9842 15 62 27.9842 62 44C62 60.017 74.9842 73 91 73C107.016 73 120 60.017 120 44Z"
      fill="#FFE404"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120 44C120 27.9842 107.016 15 91 15C74.9842 15 62 27.9842 62 44C62 60.017 74.9842 73 91 73C107.016 73 120 60.017 120 44Z"
      stroke="black"
      strokeWidth="1.22239"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.9189 55.1957C75.9933 58.0768 78.7583 60.227 81.8351 61.5264C83.3435 62.2207 84.929 62.6877 86.5217 62.9884C88.1096 63.3062 89.7183 63.481 91.3306 63.4688C92.9429 63.481 94.5504 63.3087 96.1395 62.9908C97.7311 62.6901 99.3177 62.2281 100.825 61.5338C103.897 60.249 106.679 58.0609 108.733 55.2384L108.752 55.2128L108.768 55.1871L108.806 55.126L109.78 55.8643L109.745 55.923L109.779 55.8716C107.821 58.9985 105.047 61.5851 101.829 63.3209C98.6258 65.0909 94.9684 65.9967 91.3294 65.9649C87.6891 65.9943 84.0329 65.0811 80.8315 63.3111C77.6227 61.5814 74.8247 58.9594 72.9043 55.8777L73.9189 55.1957Z"
      fill="black"
    />
    <path
      d="M110.487 57.0199C110.496 56.273 110.13 55.5347 109.456 55.0897C109.446 55.0811 109.432 55.0763 109.423 55.0714C108.693 54.6093 107.804 54.6044 107.088 54.9895"
      stroke="black"
      strokeWidth="1.22239"
    />
    <path
      d="M72.1564 57.079C72.1478 56.3406 72.4987 55.6072 73.1649 55.1635C73.1698 55.1586 73.1783 55.1537 73.1832 55.1488C73.9191 54.6684 74.831 54.6598 75.5522 55.0486"
      stroke="black"
      strokeWidth="1.22239"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.8835 42.3789C86.1818 42.8765 86.3468 43.429 86.3468 44.0475C86.3468 45.8371 84.8995 47.2844 83.1099 47.2844C81.3216 47.2844 79.873 45.8371 79.873 44.0475C79.873 43.429 80.0503 42.8654 80.3376 42.3789C80.9671 42.2799 81.6076 42.2139 82.2604 42.1797C82.1711 42.3349 82.1161 42.5122 82.1161 42.6992C82.1161 43.2957 82.6026 43.7822 83.1992 43.7822C83.7957 43.7822 84.281 43.2957 84.281 42.6992C84.281 42.5122 84.237 42.3459 84.149 42.1919C84.7345 42.2249 85.32 42.2799 85.8835 42.3789Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.4458 41.6934C84.562 41.8963 84.6206 42.1139 84.6206 42.3608C84.6206 43.1444 83.9813 43.7813 83.1978 43.7813C82.4154 43.7813 81.7773 43.1444 81.7773 42.3608C81.7773 42.1139 81.8495 41.8817 81.9656 41.6788C82.3286 41.6641 82.7051 41.6494 83.0829 41.6494C83.5461 41.6494 83.996 41.6641 84.4458 41.6934Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.8837 42.379C85.3202 42.28 84.7347 42.225 84.1491 42.192C83.8069 42.17 83.4646 42.1578 83.1101 42.1578C82.8241 42.1578 82.5368 42.17 82.2605 42.1798C81.6078 42.214 80.9673 42.28 80.3377 42.379C79.1997 42.5563 78.1276 42.8435 77.1338 43.2188C76.7696 41.4292 76.5703 39.4514 76.5703 37.3746C76.5703 29.4425 79.4979 23.0127 83.1101 23.0127C86.7235 23.0127 89.6511 29.4425 89.6511 37.3746C89.6511 39.4514 89.4519 41.4292 89.0876 43.2188C88.0938 42.8435 87.0218 42.5563 85.8837 42.379Z"
      fill="#FFE404"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M89.0873 43.2187C88.0715 48.2342 85.7734 51.7363 83.111 51.7363C80.4487 51.7363 78.1506 48.2342 77.1348 43.2187C78.1286 42.8434 79.2006 42.5562 80.3374 42.3789C80.0502 42.8654 79.8741 43.4289 79.8741 44.0475C79.8741 45.837 81.3215 47.2844 83.111 47.2844C84.9006 47.2844 86.3479 45.837 86.3479 44.0475C86.3479 43.4289 86.1829 42.8764 85.8834 42.3789C87.0215 42.5562 88.0935 42.8434 89.0873 43.2187Z"
      fill="#FC8579"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M77.1338 43.2188C76.7696 41.4292 76.5703 39.4514 76.5703 37.3746C76.5703 29.4425 79.4979 23.0127 83.1101 23.0127C86.7223 23.0127 89.6499 29.4425 89.6499 37.3746C89.6499 39.4514 89.4519 41.4292 89.0864 43.2188C88.0706 48.2343 85.7725 51.7364 83.1101 51.7364C80.4477 51.7364 78.1496 48.2343 77.1338 43.2188Z"
      stroke="black"
      strokeWidth="1.22239"
    />
    <path
      d="M89.0873 43.2192C88.0935 42.844 87.0215 42.5567 85.8834 42.3795C85.3199 42.2804 84.7344 42.2254 84.1488 42.1924C83.8066 42.1704 83.4643 42.1582 83.111 42.1582C82.8238 42.1582 82.5365 42.1704 82.2602 42.1802C81.6087 42.2144 80.967 42.2804 80.3374 42.3795C79.2006 42.5567 78.1286 42.844 77.1348 43.2192"
      stroke="black"
      strokeWidth="1.22239"
    />
    <path
      d="M80.3363 42.3682V42.3792C80.0491 42.8657 79.873 43.4292 79.873 44.0477C79.873 45.8373 81.3204 47.2834 83.1099 47.2834C84.8983 47.2834 86.3468 45.8373 86.3468 44.0477C86.3468 43.4292 86.1806 42.8767 85.8823 42.3792V42.3682"
      stroke="black"
      strokeWidth="1.22239"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.454 42.3789C102.752 42.8765 102.917 43.429 102.917 44.0475C102.917 45.8371 101.47 47.2844 99.6803 47.2844C97.8907 47.2844 96.4434 45.8371 96.4434 44.0475C96.4434 43.429 96.6206 42.8654 96.9079 42.3789C97.5374 42.2799 98.1779 42.2139 98.8307 42.1797C98.7415 42.3349 98.6864 42.5122 98.6864 42.6992C98.6864 43.2957 99.1717 43.7822 99.7695 43.7822C100.366 43.7822 100.851 43.2957 100.851 42.6992C100.851 42.5122 100.807 42.3459 100.719 42.1919C101.305 42.2249 101.89 42.2799 102.454 42.3789Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.017 41.6934C101.133 41.8963 101.191 42.1139 101.191 42.3608C101.191 43.1444 100.553 43.7813 99.7693 43.7813C98.987 43.7813 98.3477 43.1444 98.3477 42.3608C98.3477 42.1139 98.421 41.8817 98.5371 41.6788C98.9002 41.6641 99.2767 41.6494 99.6532 41.6494C100.118 41.6494 100.568 41.6641 101.017 41.6934Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.454 42.379C101.891 42.28 101.305 42.225 100.719 42.192C100.377 42.17 100.035 42.1578 99.6804 42.1578C99.3932 42.1578 99.1059 42.17 98.8309 42.1798C98.1781 42.214 97.5376 42.28 96.908 42.379C95.77 42.5563 94.698 42.8435 93.7041 43.2188C93.3387 41.4292 93.1406 39.4514 93.1406 37.3746C93.1406 29.4425 96.0683 23.0127 99.6804 23.0127C103.294 23.0127 106.221 29.4425 106.221 37.3746C106.221 39.4514 106.022 41.4292 105.658 43.2188C104.663 42.8435 103.592 42.5563 102.454 42.379Z"
      fill="#FFE404"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M105.657 43.2187C104.64 48.2342 102.343 51.7363 99.6806 51.7363C97.0183 51.7363 94.7202 48.2342 93.7031 43.2187C94.6982 42.8434 95.769 42.5562 96.907 42.3789C96.6198 42.8654 96.4437 43.4289 96.4437 44.0475C96.4437 45.837 97.8898 47.2844 99.6806 47.2844C101.47 47.2844 102.918 45.837 102.918 44.0475C102.918 43.4289 102.751 42.8764 102.453 42.3789C103.591 42.5562 104.663 42.8434 105.657 43.2187Z"
      fill="#FC8579"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M93.7041 43.2188C93.3399 41.4292 93.1406 39.4514 93.1406 37.3746C93.1406 29.4425 96.0683 23.0127 99.6816 23.0127C103.294 23.0127 106.221 29.4425 106.221 37.3746C106.221 39.4514 106.022 41.4292 105.658 43.2188C104.641 48.2343 102.343 51.7364 99.6816 51.7364C97.018 51.7364 94.7212 48.2343 93.7041 43.2188Z"
      stroke="black"
      strokeWidth="1.22239"
    />
    <path
      d="M105.657 43.2192C104.663 42.844 103.591 42.5567 102.453 42.3795C101.889 42.2804 101.304 42.2254 100.718 42.1924C100.376 42.1704 100.034 42.1582 99.6806 42.1582C99.3921 42.1582 99.1061 42.1704 98.8298 42.1802C98.1771 42.2144 97.5365 42.2804 96.907 42.3795C95.769 42.5567 94.6982 42.844 93.7031 43.2192"
      stroke="black"
      strokeWidth="1.22239"
    />
    <path
      d="M96.9086 42.3682V42.3792C96.6213 42.8657 96.4453 43.4292 96.4453 44.0477C96.4453 45.8373 97.8914 47.2834 99.6822 47.2834C101.471 47.2834 102.919 45.8373 102.919 44.0477C102.919 43.4292 102.753 42.8767 102.455 42.3792V42.3682"
      stroke="black"
      strokeWidth="1.22239"
    />
  </svg>
)
